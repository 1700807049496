@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir Black';
  src: url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/fonts/avenir-arabic-black.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/fonts/avenir-arabic-book.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/fonts/avenir-arabic-heavy.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Light';
  src: url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/fonts/avenir-arabic-light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/fonts/avenir-arabic-medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/fonts/Gotham-Black.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #EDEDED; */
}

a, button, img {
  user-select: none;
}

.layout {
  height: 100vh;
  max-width: 2000px;
  margin: auto;
}

.profile-pic {
  border-radius: 50%;
  /* max-width: 200px; */
  height: 150px !important;
  width: 150px !important;
  object-fit: cover;
}

.custom-datepicker {
  width: 150px !important;
}
 
.swiper-button-next,
.swiper-button-prev {
  color: var(--swiper-navigation-color, #FFFFFF) !important; 
}

.swiper-pagination-bullet {
  background-color: var(--swiper-pagination-color, #CCCCCC) !important; 
}

.swiper-pagination-bullet-active {
  background-color: var(--swiper-pagination-active-color, #0000FF) !important;
}

@layer components {
  .bg-gradient-radial {
    background: linear-gradient(rgba(0,0,0,.25) 0%, rgba(0,0,0,.5) 25%, rgba(0,0,0,.5) 75%, rgba(0,0,0,.25) 100%)
  }
}

@keyframes gradient-slide {
  0% {
    background-position: 0% 40%;
  }
  50% {
    background-position: 60% 40%;
  }
  100% {
    background-position: 0% 40%;
  }
}


.dragging-class {
  position: relative;
  z-index: 1000; /* Garante que o item fique sobre os demais */
  overflow: hidden; /* Previne overflow */
}

.text-wrap {
  word-break: break-word;  /* Quebra palavras que excedam a largura do contêiner */
  overflow-wrap: break-word;  /* Garante quebrar palavras para evitar overflow */
}

.introduction-section {
  height: auto; /* Altura automática para expandir baseada no conteúdo */
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #333;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000 transparent;
}
