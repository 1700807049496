/* .carousel {
  max-width: 400px;
  margin: auto;;
} */

.speaker-slider {
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.speaker-slider .swiper-slide {
  transition: transform 0.3s;
  transform: scale(0.40) !important;
}

.speaker-slider .swiper-slide-active {
  transform: scale(1) !important;
}

.swiper-pagination-bullet, .swiper-pagination {
  position: relative;
  border-radius: 0px;
}

/* .carousel img {
  margin: auto;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
} */